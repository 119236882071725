
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import ValidateInput, { RulesProp } from '../form/ValidateInput.vue';
import ValidateForm from '../form/ValidateForm.vue';
import createTooltip from '../globalFun/createTooltip';
export default defineComponent({
  name: 'StartRetrieve',
  components: {
    ValidateInput,
    ValidateForm
  },
  setup() {
    const store = useStore();
    // 1.接收ValidateInput组件发送过来的值  2.发送默认值给ValidateInput组件
    const userEmailVal = ref('');
    // 定义表单验证规则 发送到 ValidateInput组件中
    const userEmailRule: RulesProp = [
      { type: 'null', message: 'The email address cannot be empty' },
      { type: 'userEmail', message: 'Please enter the correct email address' }
    ];

    /**
     * form-submit事件触发的函数
     * 参数: result ValidateForm组件通过 form-submit 事件传来的最终结果
     */
    const onFormSubmit = async (result: boolean) => {
      if (result) {
        // 发送找回密码邮件
        try {
          const res = await store.dispatch(
            'sendRetrievePassword',
            userEmailVal.value
          );
          createTooltip(res.message, 'default', 10000);
        } catch (error) {
          // console.log(error);
        }
      } else {
        // console.log('不通过');
      }
    };

    return {
      userEmailRule,
      userEmailVal,
      onFormSubmit
    };
  }
});
